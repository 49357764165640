.article {
    display: flex;
    position: relative;
    padding: 1vw 5vw 5vw;
    height: auto;
    background-color: #fff;

    @media print,
    screen and (min-width: 40em) {
        height: 60vh;
    }

    a:hover {
        text-decoration: none;

        .title,
        .largeTitle {
            text-decoration: underline;
        }
    }
}

.articlesDetails {
    padding: 0 2.5vw;

    @media print,
    screen and (min-width: 40em) {
        padding: 0 2vw;
    }
}

.layoutDefault {
    height: 100%;

    .link {
        align-self: flex-start;
    }

    .articleImg {
        display: none;
    }
}

.layoutImageTop,
.layoutImageTopWithoutHover {
    flex-direction: column;
    overflow: hidden;
    padding: 0;
    height: 100%;

    .link,
    .largeLink {
        margin: -3.25rem 0 0rem;
        height: 100%;
        padding: 0 1rem;
        color: #000;

        @media print,
        screen and (min-width: 64em) {
            margin: -3.75rem 0 0rem;
            padding: 0 1rem 1rem 1rem;
        }
    }

    .largeTag,
    .tag {
        margin-left: 1rem;
    }

    .title,
    .largeTitle,
    .teaserText {
        padding: 1rem 1rem 0 1rem;
        margin: 0 0 1rem 0;
        background-color: #fff;
    }

    .author {
        display: block;
        padding: 1rem;
        line-height: 1.3;
    }

    .title {
        padding-bottom: 0;
    }

    .teaserText {
        padding: 0 1rem;
        font-size: 1.125rem;
    }
}

.layoutImageTop {

    .articleImg {
        width: 100%;
        transform-origin: top;
        transform: scale(1);
        transform-style: preserve-3d;
        transition: .3s transform, .3s filter cubic-bezier(.455, .03, .515, .955);
        filter: brightness(90%) saturate(30%);
    }

    &:hover {
        .articleImg {
            transform: scale(.9);
            filter: brightness(100%)  saturate(100%);
        }
    }
}

.layoutImageRight {

    @media print,
    screen and (min-width: 40em) {
        padding: 0;
    }

    .largeLink {

        @media print,
        screen and (min-width: 40em) {
            padding: 4vh;
            flex: 0 0 50%;
            width: 50%;
            align-self: flex-start;
            color: #000;
        }

        @media print,
        screen and (min-width: 64em) {
            flex: 0 0 30%;
            width: 30%;
        }
    }

    .articleImg {

        @media print,
        screen and (min-width: 40em) {
            flex: 0 0 50%;
            width: 50%;
            left: auto;
        }

        @media print,
        screen and (min-width: 64em) {
            flex: 0 0 70%;
            width: 70%;
        }
    }
}

.layoutImageLeftSmallText {

    @media print,
    screen and (min-width: 40em) {
        height: 45vh;
        padding: 0;
    }

    .articleImg {

        @media print,
        screen and (min-width: 40em) {
            flex: 0 0 50%;
            width: 50%;
        }

        @media print,
        screen and (min-width: 64em) {
            flex: 0 0 30%;
            width: 30%;
        }
    }

    .largeLink {

        @media print,
        screen and (min-width: 40em) {
            align-self: flex-start;
            flex: 0 0 50%;
            width: 50%;
            margin-left: calc(50% + 2rem);
            padding: 0 2rem 0 0;
            color: #000;
        }

        @media print,
        screen and (min-width: 64em) {
            flex: 0 0 70%;
            width: 70%;
            margin-left: calc(30% + 2rem);
            padding: 0 4rem 0 0;
        }
    }
}

.layoutImageBehind {
    align-items: flex-end;

    .articlesDetails & {
        height: 40vh;
    }

    @media print,
    screen and (min-width: 40em) {
        height: 65vh;
        .articlesDetails & {
            height: 40vh;
        }
    }

    @media print,
    screen and (min-width: 64em) {
        
        .articlesDetails & {
            height: 80vh;
        }
    }

    .layoutArticleDetails {
        width: 100%;
        max-width: 100rem;
    }

    .largeLink {
        align-self: flex-end;
        color: #000;

        @media print,
        screen and (min-width: 40em) {
            flex: 0 0 60%;
            width: 60%;
        }

        @media print,
        screen and (min-width: 64em) {
            flex: 0 0 50%;
            width: 50%;
        }

        .articlesDetails & {
            cursor: auto;
        }

        &:hover {
            .articlesDetails & {
                text-decoration: none;
            }
        }
    }

    .teaserText,
    .largeTitle {
        padding: 1rem;
        background-color: #fff;

        .articlesDetails & {
            padding: 0;
            color: #fff;
            background-color: transparent;
        }
    }

    .largeTitle {
        font-size: 2rem;
        .articlesDetails & {
            margin-bottom: .5rem;
            font-size: 1.75rem;
            @media print,
            screen and (min-width: 40em) {
                font-size: 3.5rem;
            }
        }
    }

    .teaserText {
        .articlesDetails & {
            display: none;
        }
    }
    .reading,
    .author {
        color: #fff;
    }

    .author {
        margin-right: 1rem;
        border-right: 1px solid #fff;
        padding-right: 1rem;

    }
}

.largeLink,
.link {
    position: relative;
    display: block;
    color: #000;
    z-index: 1;
}

.largeLink {
    color: #fff;
}

.largeTag,
.tag {
    display: inline-block;
    margin: 1rem .625rem 0 0;
    padding: .5rem .625rem;
    font-size: .75rem;
    line-height: 1;
    font-weight: 500;
    letter-spacing: 1px;
    color: #fff;
    border: 1px solid transparent;
    background-color: #000;

    @media print,
    screen and (min-width: 64em) {
        margin: 1rem .625rem 2rem 0;
    }
}

.articleImg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    filter: brightness(70%);
}

.title,
.largeTitle {
    margin: 1rem 0;
    font-weight: 700;
    hyphens: auto;
    font-size: 1.5rem;
    line-height: 1.1;

    @media print,
    screen and (min-width: 64em) {
        font-size: 1.75rem;
    }
}

.teaserText {
    margin: .625rem 0;
    font-size: 1.125rem;
    line-height: 1.6;
}

.reading,
.author {
    display: inline-block;
    font-size: .875rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1;
}

.copyright {
    position: absolute;
    right: 1rem;
    bottom: .5rem;
    color: #fff;
    z-index: 10;
    p {
        font-size: .6rem;
        margin: 0;
    }
}